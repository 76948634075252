<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'iban')"
              :label="$t('hr.employee.financial.iban')"
              rules="max:50"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.iban"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'bankAccountNumber')"
              :label="$t('hr.employee.financial.bank_account_number')"
              rules="max:50"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.bankAccountNumber"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'ticketNumber')"
              :label="$t('hr.employee.financial.ticket_number')"
              rules="max:50"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.ticketNumber"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'bankBranch')"
              :label="$t('hr.employee.financial.bank_branch')"
              rules="max:50"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.bankBranch"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'healthInsurancePersonCount')"
              :label="$t('hr.employee.financial.health_insurance_person_count')"
              rules="integer|min_value:0"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-decimal
                  v-model.number="employeePayload.healthInsurancePersonCount"
                  min="0"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'ticketPrice.amount')
                  ? `${$helpers.get(employee, 'ticketPrice.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'ticketPrice.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.ticket_price')"
              rules="min_value:0|max_value:999999"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.ticket_price')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.ticketPrice.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.ticketPrice.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.ticketPrice.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'previousTicketPrice.amount')
                  ? `${$helpers.get(employee, 'previousTicketPrice.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'previousTicketPrice.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.previous_ticket_price')"
              rules="min_value:0|max_value:999999"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.previous_ticket_price')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.previousTicketPrice.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.previousTicketPrice.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.previousTicketPrice.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'salary.amount')
                  ? `${$helpers.get(employee, 'salary.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'salary.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.salary')"
              rules="min_value:0|max_value:999999"
              :index="7"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.salary')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.salary.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.salary.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.salary.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'previousSalary.amount')
                  ? `${$helpers.get(employee, 'previousSalary.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'previousSalary.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.previous_salary')"
              rules="min_value:0|max_value:999999"
              :index="8"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.previous_salary')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.previousSalary.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.previousSalary.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.previousSalary.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'yearlyPremium.amount')
                  ? `${$helpers.get(employee, 'yearlyPremium.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'yearlyPremium.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.yearly_premium')"
              rules="min_value:0|max_value:999999"
              :index="9"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.yearly_premium')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.yearlyPremium.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.yearlyPremium.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.yearlyPremium.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'monthlyStoppage.amount')
                  ? `${$helpers.get(employee, 'monthlyStoppage.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'monthlyStoppage.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.monthly_stoppage')"
              rules="min_value:0|max_value:999999"
              :index="10"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.monthly_stoppage')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.monthlyStoppage.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.monthlyStoppage.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.monthlyStoppage.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'agi.amount')
                  ? `${$helpers.get(employee, 'agi.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'agi.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.agi')"
              rules="min_value:0|max_value:999999"
              :index="11"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.agi')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.agi.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.agi.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.agi.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'grossSalary.amount')
                  ? `${$helpers.get(employee, 'grossSalary.amount')} ${$t(
                      `currency.codes.${$helpers.get(employee, 'grossSalary.code')}`
                    )}`
                  : null
              "
              :label="$t('hr.employee.financial.gross_salary')"
              rules="min_value:0|max_value:999999"
              :index="12"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template>
                <div class="d-flex align-start">
                  <validation-provider
                    :name="$t('hr.employee.financial.gross_salary')"
                    slim
                    rules="min_value:0|max_value:999999"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-decimal
                      v-model.number="employeePayload.grossSalary.amount"
                      min="0"
                      outlined
                      dense
                      color="tertiary"
                      style="max-width: 200px;"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('currency.code')"
                    slim
                    :rules="{
                      required: !!employeePayload.grossSalary.amount
                    }"
                    v-slot="{ errors }"
                  >
                    <currency-picker
                      v-model="employeePayload.grossSalary.code"
                      color="tertiary"
                      class="ml-2"
                      style="max-width: 150px;"
                      outlined
                      dense
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'netPay')
                  ? $t('hr.employee.financial.pay_to_net')
                  : $t('hr.employee.financial.pay_to_gross')
              "
              :label="$t('hr.employee.financial.salary_type')"
              :index="13"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-radio-group row mandatory v-model="employeePayload.netPay" class="mt-1 pt-0">
                  <v-radio color="primary" :value="true" :label="$t('hr.employee.financial.pay_to_net')" />
                  <v-radio color="primary" :value="false" :label="$t('hr.employee.financial.pay_to_gross')" />
                </v-radio-group>
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="14 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_FINANCIAL } from "../query";

  export default {
    name: "EmployeeFinancial",
    props: {
      profileSettings: Object
    },
    components: {
      CurrencyPicker: () => import("@/components/selectpickers/CurrencyPicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        iban: null,
        bankAccountNumber: null,
        bankBranch: null,
        ticketNumber: null,
        netPay: null,
        healthInsurancePersonCount: null,
        ticketPrice: {
          amount: null,
          code: null
        },
        previousTicketPrice: {
          amount: null,
          code: null
        },
        salary: {
          amount: null,
          code: null
        },
        previousSalary: {
          amount: null,
          code: null
        },
        yearlyPremium: {
          amount: null,
          code: null
        },
        monthlyStoppage: {
          amount: null,
          code: null
        },
        agi: {
          amount: null,
          code: null
        },
        grossSalary: {
          amount: null,
          code: null
        },
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.FINANCIAL);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateFinancialCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeFinancial();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload.iban = employee.iban;
        this.employeePayload.ticketNumber = employee.ticketNumber;
        this.employeePayload.bankAccountNumber = employee.bankAccountNumber;
        this.employeePayload.bankBranch = employee.bankBranch;
        this.employeePayload.healthInsurancePersonCount = employee.healthInsurancePersonCount;
        this.employeePayload.netPay = employee.netPay;
        this.employeePayload.ticketPrice.amount = this.$helpers.get(employee, "ticketPrice.amount");
        this.employeePayload.ticketPrice.code = this.$helpers.get(employee, "ticketPrice.code");
        this.employeePayload.previousTicketPrice.amount = this.$helpers.get(employee, "previousTicketPrice.amount");
        this.employeePayload.previousTicketPrice.code = this.$helpers.get(employee, "previousTicketPrice.code");
        this.employeePayload.salary.amount = this.$helpers.get(employee, "salary.amount");
        this.employeePayload.salary.code = this.$helpers.get(employee, "salary.code");
        this.employeePayload.previousSalary.amount = this.$helpers.get(employee, "previousSalary.amount");
        this.employeePayload.previousSalary.code = this.$helpers.get(employee, "previousSalary.code");
        this.employeePayload.monthlyStoppage.amount = this.$helpers.get(employee, "monthlyStoppage.amount");
        this.employeePayload.monthlyStoppage.code = this.$helpers.get(employee, "monthlyStoppage.code");
        this.employeePayload.yearlyPremium.amount = this.$helpers.get(employee, "yearlyPremium.amount");
        this.employeePayload.yearlyPremium.code = this.$helpers.get(employee, "yearlyPremium.code");
        this.employeePayload.agi.amount = this.$helpers.get(employee, "agi.amount");
        this.employeePayload.agi.code = this.$helpers.get(employee, "agi.code");
        this.employeePayload.grossSalary.amount = this.$helpers.get(employee, "grossSalary.amount");
        this.employeePayload.grossSalary.code = this.$helpers.get(employee, "grossSalary.code");
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeeFinancial() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_FINANCIAL,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.financial;
              this.setEmployeePayload(data.employee.financial);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeFinancial();
    }
  };
</script>
